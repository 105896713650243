import React from 'react'
import "./footer.css"
import { Form, Col, Button } from 'react-bootstrap'
import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faYoutubeSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core'
import Wave2 from "../images/wave_2.png"
// Disable the auto CSS insertion
import Instagram from "../components/instagram"

config.autoAddCss = false

export default function FooterHome() {
    return (
        <>
          <div id="footer">
            {/*<img src={Wave2} alt="" id="wave-2"/>*/}
              {/* <Instagram/> */}
            <div className="bg-color-footer">
              <div id="right-div">
                <div className="contactinfo">
                  <h2 className="contact-person">Eduardo Gouveia</h2>
                  <h3 className="contact-info"><span><a href="tel:+32 488 52 02 60" className="email-link">+32 488 52 02 60</a></span></h3>
                  <h3 className="contact-info"><span><a href="mailto: info@echorses.com" className="email-link">info@echorses.com</a></span></h3>
                  <h3 className="contact-info"><span><a href="https://www.google.com/maps/place/Smeetshofweg+71,+3990+Peer,+Belgium/@51.1453934,5.4934579,17z/data=!3m1!4b1!4m5!3m4!1s0x47c0d5ef67e7b3fd:0x4fb09e98ca3430f6!8m2!3d51.1453934!4d5.4956466" className="email-link">Smeetshofweg 71,<br /> 3990 Peer, <br /> Belgium </a></span></h3>
                </div>

                <div className="mapwithicons">

                        <div className="map">
                          <a href="https://www.google.com/maps/place/Smeetshofweg+71,+3990+Peer,+Belgium/@51.1453934,5.4934579,17z/data=!3m1!4b1!4m5!3m4!1s0x47c0d5ef67e7b3fd:0x4fb09e98ca3430f6!8m2!3d51.1453934!4d5.4956466">
                            <StaticGoogleMap size="320x170"  id="googlemap" className="img-fluid" apiKey="AIzaSyCEMRXdqU638OrNX7YNIWzx3cgQCK0h5Vk">
                                <Marker location="51.14556837732422, 5.4957431577514475" color="red" label="" />
                            </StaticGoogleMap>
                          </a>
                        </div>

                        <div className="social-media-footer">
                            <a className="nav-icon2" alt="Instagram icon" href="https://www.instagram.com/ec.horses/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} className="fa-fw" />
                            </a>
                            <a className="nav-icon2" alt="Facebook icon" href="https://www.facebook.com/ec.horses17" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebookSquare} className="fa-fw" />
                            </a>
                            <a className="nav-icon2" alt="Youtube icon" href="https://www.youtube.com/channel/UCSaoMvPuMr3gzUjh32qWGbg" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faYoutubeSquare} className="fa-fw" />
                            </a>
                            <a className="nav-icon2" alt="Whatsapp icon" href="https://wa.me/032475967562" target="_blank" rel="noopener noreferrer">
                              <FontAwesomeIcon icon={faWhatsapp} className="fa-fw" />
                            </a>

                        </div>

                </div>

              </div>
              <div id="left-div">

                  <Form method="post" action="https://formspree.io/f/mqkwzppj">

                      <Col className="contactrow">
                          <Form.Control placeholder="name" className="placeholder" />
                      </Col>

                      <Col className="contactrow">
                          <Form.Control placeholder="e-mail" name="_replyto" className="placeholder" />
                      </Col>

                      <Col className="contactrow">
                          <Form.Control placeholder="message" className="placeholder-message" />
                      </Col>

                      <Col className="contactrow">
                          <Button id="btn" type="submit" size="sm">send</Button>
                      </Col>

                  </Form>

              </div>
            </div>
          </div>
        </>
    )
}
