import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import 'bootstrap/dist/css/bootstrap.min.css'

import Homepage from "../pages/Home"
import Navbar from "../components/Nav"
import FooterHome from "../components/footer-home"
import Navigation from "../components/Nav"

export default function Home() {
  return (
    <>
       <Helmet>
          <meta charSet="utf-8" />
          <title>EC Horses</title>
          <meta name="description" content="Helmet application" />
          <link rel="canonical" href="http://echorses.com" />
        </Helmet>
      <Navbar/>
      <Homepage/>
      <FooterHome/>
    </>
  )
}
